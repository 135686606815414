
export default {
  props: {
    product: {
      default: () => {},
    },
    type: {
      default: () => {},
    },
  },
}
