
import AlreadyHaveProduct from './AlreadyHaveProduct.vue'
import ProductDetail from './ProductDetail.vue'
import InnerPageTitle from '@/components/Shared/InnerPageTitle/index.vue'

export default {
  components: { AlreadyHaveProduct, InnerPageTitle, ProductDetail },
  props: {
    product: {
      default: () => {},
    },
    type: {
      default: () => {},
    },
  },
}
