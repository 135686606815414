
import Modal from '@/components/Shared/Modal/index.vue'
import FastLogin from '@/components/Contents/SeanceContent/FastLogin.vue'
import Purchase from '@/components/Contents/PurchaseAction/Purchase.vue'
import { mapGetters } from 'vuex'
import { Currency } from '~/plugins/Currency'
export default {
  components: {
    Modal,
    FastLogin,
    Purchase,
  },
  props: {
    item: {
      default: () => {},
    },
  },
  data() {
    return {
      noImage: false,
      showModal: false,
      showFastLogin: false,
      showPayments: false,
      boxes: ['Info', 'JoinLink', 'EventVideo'],
      activeBox: null,
    }
  },
  computed: {
    ...mapGetters({
      getActiveUser: 'user/getActiveUser',
      getUserIP: 'user/getUserIP',
      getSettings: 'getSettings',
    }),
  },
  methods: {
    Currency(p) {
      return Currency(p, this.getUserIP.currency)
    },
    IS_FREE(price, discount) {
      const discountedPrice = (price * discount) / 100
      if (price - discountedPrice === 0) return true
      else false
    },
    LOGIN_SUCCESS() {
      this.showFastLogin = false
      this.showPayments = true
    },
    JOIN_EVENT() {
      this.showModal = false
      if (!this.getActiveUser.token) return (this.showFastLogin = true)
      else this.showPayments = true
    },
    showDetail(id) {
      if (id !== this.item.eventItemId) return
      this.showModal = true
    },
    SET_END_DATE() {
      const date = new Date(this.item.date + '+03:00')
      const endDate = date.setMinutes(this.item.duration)
      return this.$date.formatOnlyTime(new Date(endDate))
    },
    GET_DATE() {
      return new Date(this.item.date + '+03:00').getDate()
    },
    GET_FULLYEAR() {
      return new Date(this.item.date + '+03:00').getFullYear()
    },
    GET_MONTH() {
      return new Date(this.item.date + '+03:00').toLocaleDateString(
        this.$i18n.locale,
        { month: 'short' }
      )
    },
  },
}
