
export default {
  props: {
    item: {
      default: () => {},
    },
  },
  data() {
    return {
      intervl: -1,
      closedComponent: false,
      remainDays: {
        day: 0,
        hour: 0,
        minute: 0,
        second: 0,
      },
    }
  },
  methods: {
    FILL_ZERO(n) {
      return n < 10 ? '0' + n : n
    },
    dhm(ms) {
      const day = Math.floor(ms / (24 * 60 * 60 * 1000))
      const daysms = ms % (24 * 60 * 60 * 1000)
      const hour = Math.floor(daysms / (60 * 60 * 1000))
      const hoursms = ms % (60 * 60 * 1000)
      const minute = Math.floor(hoursms / (60 * 1000))
      const minutesms = ms % (60 * 1000)
      const second = Math.floor(minutesms / 1000)
      return { day, hour, minute, second }
    },
    INTERVAL() {
      const eventDate = new Date(this.item.date + '+03:00').getTime()
      this.intervl = setInterval(() => {
        const nowDate = new Date().getTime()
        const remain = eventDate - nowDate
        if (remain < 0) {
          this.closedComponent = true;
          clearInterval(this.intervl)
        }
        this.remainDays = this.dhm(remain)
      }, 1000)
    },
  },
  mounted() {
    this.INTERVAL()
  },
}
