
// import Feedbacks from '../Feedbacks/index.vue'
import InnerLoading from '~/components/Shared/Loading/InnerLoading.vue'
import EventListOfUsListItem from './ListItem.vue'
import ClosestEvent from './ClosestEvent.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    EventListOfUsListItem,
    InnerLoading,
    ClosestEvent,
  },
  data() {
    return {
      isLoading: true,
      page: 1,
      count: 0,
      items: [],
      
    }
  },
  computed: {
    ...mapGetters({
      getActiveUser: 'user/getActiveUser',
      getUserIP: 'user/getUserIP'
    }),
  },
  methods: {
    NEW_PAGE() {
      this.page += 1
      this.GET_ITEMS(false)
    },
    FIND_REF(id) {
      const ref = this.$refs.details.find((r) => r.item.eventItemId === id)
      if (ref) ref.showDetail(id)
    },
    CHECK_QUERY() {
      if (process.server) return
      const event = this.$route.query.event
      if (!isNaN(Number(event))) this.FIND_REF(Number(event))
    },
    async GET_ITEMS(loading = true) {
      this.isLoading = loading
      let link = `UserEvents/ExternalEvents?currency=${this.getUserIP.currency}&limit=10&page=${this.page}`
      const response = await this.$api.get(link)
      if (response.message === 'OK') {
        this.items = [...this.items, ...response.data.items]
        this.count = response.data.count
        setTimeout(() => {
          this.CHECK_QUERY()
        }, 250)
      } else this.items = []
      this.isLoading = false
    },
  },
  created() {
    this.GET_ITEMS()
  },
}
